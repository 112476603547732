<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 100px">
          <a-input
              class="input"
              v-model="queryParams.goodId"
              placeholder="商品ID"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.goodName"
              placeholder="商品名称"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名称"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.sendStatus"
              placeholder="登记状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.cardOrderSendStatus" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.kbsStatus"
              placeholder="卡博士状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.cardOrderKbsStatus" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
      <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">
        新增
      </a-button>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="goodName" slot-scope="text, record">
        <div style="display: flex;align-items: center">
          <el-image
              v-if="record.goodsInfo"
              style="min-width: 80px; min-height: 60px;max-width: 80px; max-height: 60px"
              :src="record.goodsInfo.goods_pic | fullKbsImgPath"
              fit="contain"
              :preview-src-list="[fullKbsImgPath(record.goodsInfo.goods_pic)]"
          />
          <div style="flex:1;font-size:0.9em;margin-left:5px">
            {{ record.goodName }}
          </div>
        </div>
      </template>

      <template slot="sendStatus" slot-scope="text, record">
        <a-tag :color="record.sendStatus | dictName('cardOrderSendStatus', 'color')" style="cursor: pointer">
          {{ record.sendStatus| dictName("cardOrderSendStatus") }}
        </a-tag>
      </template>
      <template slot="kbsStatus" slot-scope="text, record">
        <a-tag :color="record.kbsStatus | dictName('cardOrderKbsStatus', 'color')" style="cursor: pointer">
          {{ record.kbsStatus| dictName("cardOrderKbsStatus") }}
        </a-tag>
      </template>
      <template slot="username" slot-scope="text, record">
        <div>{{ record.username }}</div>
        <div>{{ record.phone }}</div>
      </template>
      <template slot="address" slot-scope="text, record">
        <div style="font-size:0.85em">
          {{ record.provinceName }}{{ record.cityName }}{{ record.areaName }}{{ record.address }}
        </div>
      </template>
      <template slot="kbsQueryJson" slot-scope="text, record">
          <div style="overflow: auto;max-height:100px;font-size:0.85em;">
            {{ (record.kbsQueryJsonObj && record.kbsQueryJsonObj.note) || text }}
          </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button v-if="record.sendStatus==1" type="primary" icon="reload" size="small" @click="syncStatus(record)">
          同步
        </a-button>
        <a-button v-else type="danger" icon="delete" size="small" @click="deleteOne(record)" style="margin-left:10px">
          删除
        </a-button>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";

const fullKbsImgPath = function (path) {
  if (!path) {
    return '';
  }
  if (path.indexOf("/uploads") == 0) {
    return "https://wap.kaboshihaoka.com" + path;
  }
  return path
}

export default {
  name: "CardOrderList",
  mixins: [ListMixin],
  filters: {fullKbsImgPath},
  data() {
    return {
      listGetUrl: "cardSale/orderList",
      listMethod: "get",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "粉丝ID",
          align: "center",
          width: 80,
          dataIndex: "uid",
        },
        {
          title: "粉丝名称",
          align: "center",
          width: 120,
          dataIndex: "userInfo.username",
        },
        {
          title: "商品ID",
          align: "center",
          width: 80,
          dataIndex: "goodId",
        },
        {
          title: "商品名称",
          align: "center",
          width: 240,
          dataIndex: "goodName",
          scopedSlots: {customRender: "goodName"},
        },
        {
          title: "登记状态",
          align: "center",
          width: 100,
          dataIndex: "sendStatus",
          scopedSlots: {customRender: "sendStatus"},
        },
        {
          title: "登记日期",
          align: "center",
          width: 120,
          dataIndex: "createTime",
        },
        {
          title: "登记用户",
          align: "center",
          width: 120,
          dataIndex: "username",
          scopedSlots: {customRender: "username"},
        },
        {
          title: "地址",
          align: "center",
          width: 160,
          dataIndex: "address",
          scopedSlots: {customRender: "address"},
        },
        {
          title: "卡博士状态",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "kbsStatus"},
        },
        {
          title: "最后同步时间",
          width: 120,
          dataIndex: "kbsQueryTime",
          align: "center",
        },
        {
          title: "最后同步信息",
          width: 200,
          align: "center",
          dataIndex: "kbsQueryJson",
          scopedSlots: {customRender: "kbsQueryJson"},
        },
        {
          title: "操作",
          width: 180,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    fullKbsImgPath,
    afterFetch(data) {
      data.records.forEach((v, index) => {
        if (v.kbsQueryJson) {
          v.kbsQueryJsonObj = JSON.parse(v.kbsQueryJson)
        }
      })
    },
    syncStatus(record) {
      this.$post('cardSale/syncOrderStatus', {id: record.id}).then(() => {
        this.$message.success('同步成功')
        this.search()
      })
    },

    deleteOne(record) {
      let that = this
      if (record.sendStatus == 1) {
        that.$message.warn('已发送到第三方，不能删除')
        return
      }
      this.$confirm({
        title: '删除确认',
        content: '您确定要删除订单【' + record.id + '】吗？！',
        centered: true,
        onOk() {
          that.$post('cardSale/deleteOrder', {id: record.id}).then(() => {
            that.$message.success('删除成功')
            that.search()
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
